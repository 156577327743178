<template>
  <div class="feed-body">
    <div class="feed-contents">
      <v-row style="width: 100%">
        <v-col v-for="volunteer in volunteers" :key="volunteer._id" cols="6">
          <agency-volunteer-profile-card :_id="volunteer" />
        </v-col>
        <div
          v-if="volunteers.length == 0 && !loading"
          class="d-flex flex-column"
        >
          <div class="mb-3">
            <v-img
              :src="require('@/assets/svg/empty-post.svg')"
              style="width: 60px; height: 50px"
              contain
            />
          </div>
          No volunteer fanned
        </div>
      </v-row>
      <v-progress-circular
        indeterminate
        color="amber"
        v-if="loading"
        class="mt-10"
      />
    </div>
    <v-snackbar v-model="snackbar">
      {{ errorMessage }}
    </v-snackbar>
  </div>
</template>
<script>
import { mapActions, mapState } from "vuex";
import AgencyVolunteerProfileCard from "../profile/AgencyVolunteerProfileCard.vue";
export default {
  components: { AgencyVolunteerProfileCard },
  data() {
    return {
      loading: false,
      errorMessage: null,
      snackbar: false,
      loadCompleted: -1,
      loadingMore: false,
      volunteers: [],
    };
  },
  methods: {
    ...mapActions("agency", {
      getFanned: "getFanned",
    }),
    showErrorMessage(message) {
      this.errorMessage = message;
      this.snackbar = true;
    },
    loadedData() {
      this.loadCompleted++;
    },
    loadMoreVolunteers() {
      this.loadingMore = true;
      this.loadMore({ skip: this.volunteers.length })
        .then(() => {
          setTimeout(() => {
            this.loadingMore = false;
          }, 2500);
        })
        .catch((error) => {
          this.loadingMore = false;
          if (error.response == undefined) {
            console.log(error);
          } else {
            this.showErrorMessage(error.response.data.message);
          }
        });
    },
    goToProfile(id) {
      this.$router.push({
        name: "volunteer-profile-details",
        params: { id },
      });
    },
  },
  computed: {
    ...mapState("auth", {
      profile: "profile",
    }),
  },
  watch: {
    profile(newValue) {
      if (newValue._id) {
        this.loading = true;
        this.loadCompleted = 0;
        this.getFanned(newValue._id)
          .then((res) => {
            this.loading = false;
            this.volunteers = res;
          })
          .catch((error) => {
            this.loading = false;
            if (error.response == undefined) {
              console.log(error);
            } else {
              this.showErrorMessage(error.response.data.message);
            }
          });
      }
    },
  },
  mounted() {
    if (this.profile._id) {
      this.loading = true;
      this.loadCompleted = 0;
      this.getFanned(this.profile._id)
        .then((res) => {
          this.loading = false;
          this.volunteers = res;
        })
        .catch((error) => {
          this.loading = false;
          if (error.response == undefined) {
            console.log(error);
          } else {
            this.showErrorMessage(error.response.data.message);
          }
        });
    }
  },
};
</script>
<style scoped>
.feed-body {
  width: 100%;
  min-width: 200px;
  margin-left: 0px;
  margin-right: 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.feed-contents {
  width: 100%;
  max-width: 650px;
  align-items: center;
  display: flex;
  flex-direction: column;
  padding-bottom: 20px;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 20px;
}
</style>
